<template>
  <v-card
    class="article-card-basko-next-gen fill-height mx-auto my-2 rounded-lg h-100"
    elevation="5"
  >
    <div class="card-inner">
      <router-link :to="articlePath" class="text-decoration-none default--text">
        <v-img
          height="250"
          :src="article.featured_image_url"
          :alt="`Basko For Next Gen: ${article.title.rendered}`"
          class="bng-article-image"
        ></v-img>

        <v-card-text class="card-category font-weight-bold primary--text pb-3">
          {{ article?.category_names[0].name }}
        </v-card-text>
        <v-card-title
          class="card-title pt-0 pb-2"
          v-html="article.title.rendered"
        ></v-card-title>
        <v-card-text>
          <div class="card-excerpt" v-html="article.excerpt.rendered"></div>
        </v-card-text>
      </router-link>
    </div>

    <v-divider class="mb-2" />
    <v-card-actions
      style="min-height: 60px;"
      class="px-4 justify-space-between"
    >
      <router-link style="color: inherit;" :to="articlePath">
        <span class="read-more-link text-underline">{{
          $t("baskoForNextGen.articleCard.readMore")
        }}</span>
      </router-link>
      <ShareButtons
        :post="article"
        direction="top"
        :slug="article.slug"
      ></ShareButtons>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.article-card-basko-next-gen {
  border-radius: 10px;
  .bng-article-image {
    border-radius: 10px 10px 0 0;
  }
  .card-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
  }
  .card-category {
    font-size: 14px;
    line-height: 20px;
  }
  .card-excerpt {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 120px !important;
  }
  .read-more-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
}
.v-speed-dial {
  &__list {
    a {
      text-decoration: none;
    }
  }
}
.social-share,
.share-btn {
  cursor: pointer;
  cursor: -moz-pointer;
  cursor: -webkit-pointer;
}
</style>
<script>
import ShareButtons from "@/components/wordpress/ShareButtons.vue";

export default {
  name: "ArticleCard",
  components: { ShareButtons },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      maxHeight: null
    };
  },
  methods: {
    sharingUrl(slug) {
      return window.location.href + slug;
    },
    setMaxHeight() {
      const elements = document.querySelectorAll(".card-description");
      let maxHeight = 0;
      for (const element of elements) {
        const elementHeight = element.clientHeight;
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      }
      this.maxHeight = maxHeight;
    }
  },
  computed: {
    articlePath() {
      return `/basko-for-next-gen/${this.article?.category_names[0].slug}/${this.article.slug_custom}`;
    }
  },
  mounted() {
    setTimeout(() => {
      this.setMaxHeight();
    }, 300);
  }
};
</script>
