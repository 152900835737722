<template>
  <div class="share-buttons primary--text font-weight-bold">
    <template>
      <v-speed-dial :direction="direction" :open="open" @click="close()">
        <template v-slot:activator>
          <v-icon color="primary" class="share-btn">$share</v-icon>
          <span v-if="extended" class="pl-2">{{ $t("posts.share") }}</span>
        </template>
        <ShareNetwork
          network="facebook"
          :url="sharingUrl"
          :title="post.title.rendered"
          :description="post.content.unrendered"
        >
          <v-btn color="#1877f2" fab small
            ><v-icon color="white">$facebook</v-icon>
          </v-btn>
        </ShareNetwork>

        <ShareNetwork
          network="whatsapp"
          :url="sharingUrl"
          :title="post.title.rendered"
          :description="post.content.unrendered"
        >
          <v-btn color="#25d366" fab small>
            <v-icon color="white">$whatsapp</v-icon>
          </v-btn>
        </ShareNetwork>

        <ShareNetwork
          network="linkedin"
          :url="sharingUrl"
          :title="post.title.rendered"
          :description="post.content.unrendered"
        >
          <v-btn color="#007bb5" fab small>
            <v-icon color="white">$linkedin</v-icon>
          </v-btn>
        </ShareNetwork>

        <ShareNetwork
          network="twitter"
          :url="sharingUrl"
          :title="post.title.rendered"
          :description="post.content.unrendered"
        >
          <v-btn color="#1da1f2" fab small>
            <v-icon color="white">$twitter</v-icon>
          </v-btn>
        </ShareNetwork>

        <ShareNetwork
          network="email"
          :url="sharingUrl"
          :title="post.title.rendered"
          :description="post.content.unrendered"
        >
          <v-btn color="#333333" fab small>
            <v-icon color="white">$mail</v-icon>
          </v-btn>
        </ShareNetwork>

        <!-- <a class="social-sharing" @click="doPrint">
          <v-btn color="#aeaeae" fab small>
            <v-icon color="white">$print</v-icon>
          </v-btn>
        </a> -->
      </v-speed-dial>
    </template>
  </div>
</template>
<style scoped lang="scss">
.share-buttons {
  cursor: pointer;
  a {
    text-decoration: none;
  }
}

.share-buttons .v-speed-dial__list {
  align-items: end;
}
</style>
<script>
import Vue from "vue";
import socialSharing from "vue-social-sharing";

export default {
  name: "ShareButtons",
  data() {
    return {
      open: false
    };
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    direction: {
      type: String,
      required: false,
      default: "top"
    },
    slug: {
      type: String,
      required: false,
      default: ""
    },
    extended: { type: Boolean, required: false, default: false }
  },
  mounted() {
    Vue.use(socialSharing);
  },
  methods: {
    close() {
      this.open = !this.open;
    }
  },

  computed: {
    sharingUrl() {
      return window.location.href + this.slug;
    }
  }
};
</script>
