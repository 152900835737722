<template>
  <div class="basko-next-gen-grid-wrapper">
    <div v-if="articles && articles.length">
      <v-row class="basko-next-gen-grid">
        <v-col
          :cols="cols"
          :sm="sm"
          :md="md"
          :lg="lg"
          :xl="xl"
          v-for="article in articles"
          :key="article.id"
        >
          <ArticleCardBNG :article="article"></ArticleCardBNG>
        </v-col>
      </v-row>
      <v-pagination
        class="mt-12"
        color="primary"
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>
    </div>
    <div v-else>
      {{ $t("article.noArticleToShow") }}
    </div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from "vue";
import socialSharing from "vue-social-sharing";
import ArticleCardBNG from "@/components/wordpress/ArticleCardBNG.vue";
import CMService from "~/service/cmService";
import get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "BaskoNextGenFooter",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  data() {
    return {
      articles: null,
      newFilters: false,
      pager: {},
      noArticleToShow: false,
      search: null
    };
  },
  components: {
    ArticleCardBNG
  },
  methods: {
    async fetchArticles(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      if (this.search != null && this.search.length > 0) {
        params.push({
          filterName: "search",
          filterValue: this.search
        });
      }
      if (this.categoryId) {
        params.push({
          filterName: "category_basko_next_gen",
          filterValue: this.categoryId
        });
      }
      const res = await CMService.getCustomPostByFilters(
        "basko-next-gen",
        params,
        this.pageSize
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.articles = res.data;
      } else {
        this.articles = null;
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchArticles(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    }),
    pageSize() {
      return get(
        this.config,
        "metaData.categoryblocktype_ArticleGridBNG.PAGE_SIZE",
        "8"
      );
    },
    categoryId() {
      return get(
        this.config,
        "metaData.categoryblocktype_ArticleGridBNG.CATEGORY_ID"
      );
    },
    cols() {
      return Number(
        get(this.config, "metaData.categoryblocktype_ArticleGridBNG.XS", 12)
      );
    },
    sm() {
      return Number(
        get(this.config, "metaData.categoryblocktype_ArticleGridBNG.SM", 6)
      );
    },
    md() {
      return Number(
        get(this.config, "metaData.categoryblocktype_ArticleGridBNG.MD", 4)
      );
    },
    lg() {
      return Number(
        get(this.config, "metaData.categoryblocktype_ArticleGridBNG.LG", 3)
      );
    },
    xl() {
      return Number(
        get(this.config, "metaData.categoryblocktype_ArticleGridBNG.XL", 3)
      );
    }
  },
  created() {
    global.EventBus.$on("searchBNG", async query => {
      this.search = query;
      await this.fetchArticles(1);
    });
  },
  mounted() {
    Vue.use(socialSharing);
    this.fetchArticles(1);
  }
};
</script>
