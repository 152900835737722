<template>
  <div
    v-if="posts"
    class="w-100 slider-main-wrapper container container--fluid"
  >
    <h4>{{ $t("baskoForNextGen.footer.title") }}</h4>
    <swiper
      :options="swiperOption"
      ref="swiperRef"
      class="px-2 pb-3 w-100 h-100"
    >
      <swiper-slide v-for="post in posts" :key="post.id" class="h-100">
        <ArticleCardBNG :article="post"></ArticleCardBNG>
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && posts.length > 1"
      :id="`card-slider-pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>
<style scoped lang="scss">
.swiper-button-next {
  right: 16px !important;
  top: 58% !important;
}
.swiper-button-prev {
  left: 16px !important;
  top: 58% !important;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: 100% !important;
}
</style>
<script>
import ArticleCardBNG from "@/components/wordpress/ArticleCardBNG.vue";
import CMService from "~/service/cmService";
import get from "lodash/get";

export default {
  name: "CardSlider",
  components: { ArticleCardBNG },
  // props: {
  //   categoryId: {
  //     type: Number,
  //     required: false
  //   },
  //   cat: {
  //     type: String,
  //     required: false
  //   },
  //   paginationClass: { type: String, required: false },
  //   showArrows: { type: Boolean, required: false, default: true },
  //   showBullets: { type: Boolean, required: false, default: true }
  // },
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    paginationClass: { type: String, required: false },
    showArrows: { type: Boolean, required: false, default: true },
    showBullets: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      posts: null,
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        autoHeight: true,
        watchOverflow: true,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: `#card-slider-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#card-slider-slider-prev-${this.paginationClass}`,
          nextEl: `#card-slider-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          600: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 15
          },
          960: {
            slidesPerView: 3.3,
            slidesPerGroup: 3
          },
          1264: {
            slidesPerView: 4.4,
            spaceBetween: 20,
            slidesPerGroup: 4
          }
        }
      }
    };
  },
  async mounted() {
    await this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      let params = [];

      if (this.random) {
        params.push({
          filterName: "random",
          filterValue: "true"
        });
      }
      if (this.categoryId) {
        params.push({
          filterName: "category_basko_next_gen",
          filterValue: this.categoryId
        });
      }
      const res = await CMService.getCustomPostByFilters(
        "basko-next-gen",
        params,
        this.limit
      );

      if (res && res.data && res.data.length) {
        this.posts = res.data;
      }
    }
  },
  computed: {
    categoryId() {
      return Number(
        get(
          this.config,
          "metaData.categoryblocktype_ArticleSliderBNG.CATEGORY_ID",
          null
        )
      );
    },
    limit() {
      return Number(
        get(
          this.config,
          "metaData.categoryblocktype_ArticleSliderBNG.LIMIT",
          10
        )
      );
    },
    random() {
      return get(
        this.config,
        "metaData.categoryblocktype_ArticleSliderBNG.RANDOM",
        true
      );
    }
  }
};
</script>
