<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="searchArticle"
    class="post-search basko-next-gen white"
  >
    <div class="d-flex">
      <v-text-field
        color="secondary"
        v-model="s"
        clearable
        outlined
        hide-details="true"
        :label="$t('baskoForNextGen.sidebar.searchPlaceholder')"
        prepend-inner-icon="$search"
      ></v-text-field>
    </div>
  </v-form>
</template>
<style scoped lang="scss">
.post-search {
  // box-shadow: 0px 3px 6px #00000029;
  border-radius: $border-radius-root;
  .v-btn {
    width: 100%;
  }
}
</style>
<script>
export default {
  name: "BaskoNextGenSearch",
  data() {
    return {
      s: null,
      valid: true
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  methods: {
    searchArticle() {
      let query = {};
      if (this.s != null && this.s.length > 0) {
        query["s"] = this.s;
      }
      global.EventBus.$emit("searchBNG", this.s);
      console.log("Global event emitted: searchBNG", this.s);
      this.$router.replace({ query });
    }
  }
};
</script>
