<template>
  <div v-if="categories && categories.length > 0">
    <h6 class="d-flex align-center">
      <v-icon class="mr-3">$folder</v-icon>
      {{ $t("baskoForNextGen.sidebar.categories") }}
    </h6>
    <v-list class="post-categories">
      <a
        v-for="(category, index) in categories"
        :key="category.templateNavItemId"
        :href="category.itemUrl"
      >
        <v-list-item class="px-0">
          {{ category.name }}
        </v-list-item>
        <!-- Show v-divider unless it's the last item in the list -->
        <v-divider v-if="index !== categories.length - 1" />
      </a>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.masonry-layout {
  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.post-categories a,
.post-courses a {
  text-decoration: none;
}
.masonry-layout {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-gap: 0;
  .masonry-panel {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    padding: 5px;
    .masonry-panel-content {
      padding: 10px;
      .v-list-item--active::before {
        background-color: initial;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .masonry-layout {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
  }
}
</style>
<script>
import CategoryService from "~/service/categoryService";

export default {
  name: "BaskoNextGenPostCategories",
  data() {
    return {
      filters: [
        // Excluding WordPress default "Uncategorized" / Senza categoia
        {
          filterName: "exclude",
          filterValue: 1
        }
      ],
      categories: null
    };
  },
  props: {
    extended: { type: Boolean, required: false, default: false }
  },
  async mounted() {
    this.categories = await this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const applicationConfig = await CategoryService.getCategoryBySlug(
        "application"
      );
      if (applicationConfig && applicationConfig.templateNavItems) {
        return applicationConfig.templateNavItems["basko-next-gen"];
      }
    }
  }
};
</script>
