<template>
  <v-list class="article-latest" v-if="articles">
    <h6 class="d-flex align-center">
      <v-icon class="mr-3">$doc</v-icon>
      {{ $t("baskoForNextGen.sidebar.highlight") }}
    </h6>
    <router-link
      v-for="article in articles"
      :key="article.id"
      :to="routerLink(article.slug)"
    >
      <v-list-item class="px-0 py-3">
        <div class="d-flex flex-row">
          <v-img
            v-if="article?.featured_image_url"
            width="150"
            :src="article.featured_image_url"
            :alt="`Basko For Next Gen: ${article.title.rendered}`"
            class="bng-article-image rounded mr-2"
          ></v-img>
          <div v-html="article.title.rendered"></div>
        </div>
      </v-list-item>
      <v-divider></v-divider>
    </router-link>
  </v-list>
</template>
<style scoped lang="scss">
.article-latest {
  a {
    text-decoration: none;
  }
}
</style>
<script>
import CMService from "~/service/cmService";

export default {
  name: "LatestPost",
  props: {
    num: {
      type: Number,
      default: 4
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      filters: [
        // Excluding WordPress default "Uncategorized" / Senza categoia
        {
          filterName: "exclude",
          filterValue: 1
        }
      ],
      articles: null
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      let params = [
        {
          filterName: "orderby",
          filterValue: "date"
        },
        {
          filterName: "order",
          filterValue: "desc"
        }
      ];
      if (this.categoryId) {
        params.push({
          filterName: "category_basko_next_gen",
          filterValue: this.categoryId
        });
      }
      const res = await CMService.getCustomPostByFilters(
        "basko-next-gen",
        params,
        this.num
      );

      if (res && res.data && res.data.length) {
        this.articles = res.data;
      }
    },
    routerLink(slug) {
      let route = null;
      if (this.isRecipe) {
        route = {
          name: "RecipeDetail",
          params: { postName: slug }
        };
      } else {
        route = {
          name: "ArticleDetail",
          params: { postName: slug }
        };
      }
      return route;
    }
  }
};
</script>
